<template>
    <div class="center-relative graph">
        <highcharts  class="chart h-full w-full" :options="options" :ref="chartId" :id="chartId"></highcharts>
    </div>
</template>

<script>
    import { reduceBigNumbers } from '../main.js'

    let gradients = [
        {
            linearGradient: {x1: 0,x2: 0,y1: 1,y2: 0},
            stops: [[0, 'rgba(49, 178, 200, 0.90'],[1, '#2363C9']]
        },{
            linearGradient: {x1: 0,x2: 0,y1: 1,y2: 0},
            stops: [[0, '#F9B254'],[1, '#E56E78']]
        }   
    ]

    export default {
        props: ['data','chartId','customColors'],
        data: function(){
            return {
                options: {
                    chart: {
                        type: 'bar',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor:'rgba(255,255,255,0)',
                        spacingBottom: 0,
                        spacingTop: 0,
                        margin:[0,0,0,0],
                        borderWidth: 0.,
                
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    credits:{
                        enabled: false
                    },
                    xAxis: {
                    },
                    exporting:{
                        enabled:false
                    },
                    yAxis: {
                        min:0,
                        title: {
                            text: '',
                        },

                        gridLineColor: '#1b2c34',
                        labels: {
                            enabled: true,
                            style:{
                                color:'#768194' 
                                
                            },
                        }
                    },
                    tooltip: {
                        enabled: false,
                        formatter: function () {
                            return reduceBigNumbers(this.y) + ' <small>€</small>';
                        }
                    },
                    plotOptions: {
                        series:{
                            stacking:'normal'
                        },
                        bar: {
                            dataLabels: {
                                enabled: true,
                                style:{
                                    color:'#fff',
                                    fontSize:'11px',
                                    fontWeight:'bold',
                                    textOutline:'0px contrast',
                                    
                                },
                                formatter: function () {
                                    return reduceBigNumbers(this.y);
                                }
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    series: [
                        {
                            name:'Ner',
                            data:[this.data[0],null],
                            color: 'rgba(49, 178, 200, 0.76)',
                            borderWidth: 0,
                            borderRadius: 5,
                            
                        },
                        {
                            name:'Ner_y1',
                            data:[null, this.data[1]],
                            color: gradients[0],
                            borderWidth: 0,
                            borderRadius: 5
                        }
                    ]
                }
            }
        },
        methods: {
            load() {

                var chart = this.$refs[this.chartId].chart

                chart.series[0].points[0].update(this.data[0])
                chart.series[1].points[1].update(this.data[1])

            },
        },
        watch: {
            data: {
                handler(n, o){
                    this.load();
                },
                deep: true
            },
        },
        computed:{
            hasSlot(){
                return (Object.keys(this.$slots).length > 0) ? true: false;
            }
        },
        mounted() {

            if(this.customColors){
                var chart = this.$refs[this.chartId].chart
                chart.update({
                    colors:this.customColors
                })
            }
            this.load();
        }
    };
</script>
<style lang="scss">
    .graph{
        height: 100% !important;
        width: 100% !important;
        .chart{
            height: 100% !important;
            width: 100% !important;
            .highcharts-container{
                height: 100% !important;
                width: 100% !important;
            }
        }         
    }
    .center-relative{
        position: relative;
        .center-absolute{
            position: absolute;
            top:50%;
            left:50%;
            right:auto;
            transform: translate(-50%,-50%);
        }
    }


   
</style>
